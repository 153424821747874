<template>
  <div>
    <div class="page-bgf6">
      <div class="wrapper-content">
        <div class="partners-title">
          <h2>申请退款</h2>
        </div>
        <div class="partners-content">
          <div class="orderBox">
            <div class="top">
              <span class="name">退款订单</span>
              <span class="text">订单编号：{{orderInfo.orderNo}}</span>
            </div>
            <div class="center">
              <el-image class="img" :src="loadUrl(orderInfo.image)" alt=""></el-image>
              <div class="title">赛事活动：{{orderInfo.goodsName}}</div>
              <div class="time">比赛时间：{{formatDateH(orderInfo.goodsStartTime)}} 至 {{formatDateH(orderInfo.goodsEndTime)}}</div>
            </div>
            <div class="bottom">退款金额：{{orderInfo.totalFee}}元</div>
          </div>
          <div class="reasonBox">
            <div class="title">退款原因</div>
            <el-input class="inputClass" type="textarea" :rows="6" placeholder="多行输入" v-model="dataForm.refundReason"></el-input>
          </div>
          <div class="fileBox">
            <span class="name">说明文件：</span>
            <el-upload :action="uploadImg()" :headers="uploadHeaders()" :show-file-list="false" :on-success="handleLogoSuccess" class="logo-upload" accept=".png,.jpg,.jpeg">
              <img v-if="dataForm.fileUrl" :src="loadUrl(dataForm.fileUrl)" class="logo"/>
              <i v-else class="el-icon-plus avatar-uploader-icon uploadIcon"></i>
            </el-upload>
          </div>
          <div class="partners-btn">
            <div class="btn-bar">
              <el-button @click="resetForm">取消</el-button>
              <el-button type="primary" @click="dataFormSubmit" :loading="btnLoading">确定</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  newOrderOrderInfo, orderRefundApply,
} from "../../api/order";

export default {
  name: "orderRefund.vue",
  data(){
    return{
      btnLoading:false,
      orderInfo:{},
      dataForm:{
        "fileUrl": "",
        "goodsId": "",
        "goodsName": "",
        "orderNo": "",
        "refundReason": "",
      },
      baseRule:{
        name: [
          // { required: true, message: '请输入供应商名称', trigger: 'blur' },
        ],
      },
      imgUrl:''
    }
  },
  created() {
    this.getNewOrderOrderInfo(this.$route.params.id)
  },
  methods:{
    getNewOrderOrderInfo(id){
      newOrderOrderInfo(id).then(res=>{
        if(res.code == 200){
          this.orderInfo = res.data
        }
      }).catch(()=>{})
    },
    handleLogoSuccess(res) {
      this.dataForm.fileUrl = res.data.url;
      this.imgUrl = this.loadUrl(res.data.url);
    },
    dataFormSubmit(){
      let params = {
        "fileUrl": this.dataForm.fileUrl,
        "goodsId": this.orderInfo.goodsId,
        "goodsName": this.orderInfo.goodsName,
        "orderNo": this.orderInfo.orderNo,
        "refundReason": this.dataForm.refundReason
      }
      this.btnLoading = true
      orderRefundApply(params).then(res=>{
        if(res.code == 200){
          this.$message({
            message: res.msg,
            type: "success",
            duration: 3000,
            onClose: () => {
              this.visible = false;
              this.btnLoading = false;
              this.$router.go(-1)
            },
          });
        }else {
          this.$message({
            message: res.msg,
            type: "error",
            duration: 3000,
            onClose: () => {
              this.btnLoading = false;
            },
          });
        }
      }).catch(()=>{
        this.btnLoading = false
      })
    },
    resetForm(){
      this.$router.go(-1)
    },
  }
}
</script>

<style scoped lang="scss">
.el-form-item{
  margin-bottom: 15px;
}
/deep/.el-dialog--center .el-dialog__body{
  padding: 25px;
}
.JNPF-table-delBtn{
  color: red;
}
.page-bgf6{
  background: #fff;
  position: absolute;
  left: 200px;
  height: 100vh;
  width: 100%;
  overflow-y: auto;
}
.wrapper-content{
  margin-left: 0;
}
.partners-title {
  margin-top: 20px;
  padding: 0 24px;
  background: #fff;
  width: 100%;
  box-sizing: border-box;
  height: 46px;

  h2 {
    border-bottom: solid 1px #e5e5e5;
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    line-height: 45px;
  }
}

.partners-content {
  padding: 24px 24px 54px;
  background: #fff;
  width: 100%;
  box-sizing: border-box;
  min-height: 700px;
  margin-bottom: 20px;
}
.orderBox{
  width: 500px;
  border: 2px solid #eee;
  box-shadow: 0 0 10px #eee;
  .top{
    height: 50px;
    line-height: 50px;
    padding: 0 20px;
    font-weight: bold;
    .name{
      float: left;
    }
    .text{
      float: right;
    }
  }
  .center{
    position: relative;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    width: 100%;
    height: 120px;
    .img{
      position: absolute;
      width: 80px;
      height: 80px;
      top: 20px;
      left: 20px;
    }
    .title,.time{
      position: absolute;
      width: 360px;
      height: 40px;
      line-height: 40px;
      top: 20px;
      left: 120px;
      white-space:nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .time{
      top: 60px;
    }
  }
  .bottom{
    height: 50px;
    line-height: 50px;
    text-align: right;
    padding-right: 20px;
    color: red;
    font-weight: bold;
  }
}
.reasonBox{
  width: 500px;
  border: 2px solid #eee;
  box-shadow: 0 0 10px #eee;
  margin-top: 10px;
  .title{
    height: 50px;
    line-height: 50px;
    padding: 0 20px;
    font-weight: bold;
    border-bottom: 1px solid #eee;
  }
  .inputClass{
    padding: 10px 0;
    width: 480px;
    margin: 0 10px;
  }
}
.fileBox{
  width: 500px;
  height: 140px;
  margin-top: 10px;
}
.partners-btn,
.detail-btn {
  border-top: 1px solid #ececec;
  font-size: 14px;
  color: #555;
  padding: 16px;
  .checkbox-bar {
    .checkbox-content {
      color: #666666;
    }
  }

  .btn-bar {
    padding-top: 20px;
  }
}

.detail-btn {
  text-align: center;
}
.logo-upload {
  display: inline-block;
  border: 1px dashed #c0ccda;
  border-radius: 6px;
  box-sizing: border-box;
  width: 120px;
  height: 120px;
  cursor: pointer;
  line-height: 116px;
  vertical-align: top;
  vertical-align: middle;
}
.logo {
  width: 120px;
  height: 120px;
  object-fit: cover;
}
.uploadIcon {
  width: 120px;
  height: 120px;
  cursor: pointer;
  line-height: 116px;
  font-size: 28px;
  color: #8c939d;
}
</style>
