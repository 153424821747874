import request from '@/util/request'

// 查询订单列表
export const getOrderList = (params) => {
  return request({
    url: '/api/goods/order/list',
    method: 'GET',
    params
  })
}
//新的订单列表
export const getOrderList1 = (params) => {
  return request({
    url: '/api/order/order/page',
    method: 'GET',
    params
  })
}


// 查询订单列表
export const getOrderListById = (order_Number,params) => {
  return request({
    url: `/api/goods/order/orderInfo/${order_Number}`,
    method: 'GET',
    params
  })
}


// 查询订单列表详情个人信息（赛事）
// {businessId}
export const getCompetitionOrderDetail = (params) => {
  return request({
    url: '/api/competitionUniversal/app/myCompetitionOrderDetais',
    method: 'GET',
    params
  })
}

// 查询订单列表详情个人信息（培训）
// {businessId}
export const getTrainOrderDetail = (params) => {
  return request({
    url: '/api/train/trainGroupApply/getApplyDataByBusinessId',
    method: 'GET',
    params
  })
}



//通过id获取列表
export function getMemberApplyListById(id,data) {
  return request({
    url: `/api/member/memberApply/${id}`,
    method: 'GET',
    data: data
  })
}



//删除订单
export function delMemberList(order_number,data) {
  return request({
    url: `/api/goods/order/delOrder/${order_number}`,
    method: 'DELETE',
    data: data
  })
}


//取消订单
export function cancelMemberList(order_number,data) {
  return request({
    url: `/api/goods/order/cancelOrder/${order_number}`,
    method: 'POST',
    data: data
  })
}


// 新的订单接口
//后台/网站-订单列表
export function newOrderList(params) {
  return request({
    url: "/api/order/order/page",
    method: 'get',
    params
  })
}
//后台/网站-订单列表(会员)
export function newOrderMemberInfo(id) {
  return request({
    url: "/api/order/order/memberInfo/"+id,
    method: 'get',
  })
}
//后台/网站-订单详情（赛事/培训活动）
export function newOrderOrderInfo(id) {
  return request({
    url: "/api/order/order/info/"+id,
    method: 'get',
  })
}
//网站-取消订单
export function newOrderOrderCancelOrder(id) {
  return request({
    url: "/api/order/order/cancelOrder/"+id,
    method: 'post',
  })
}
//网站-申请开票
export function newOrderOrderInvoiceApply(data) {
  return request({
    url: "/api/order/order/invoice/apply",
    method: 'post',
    data
  })
}
//网站-添加/修改发票抬头
export function newOrderOrderInvoiceAdd(data) {
  return request({
    url: "/api/order/order/invoice/edit",
    method: 'post',
    data
  })
}
//网站-发票抬头列表
export function newOrderOrderInvoiceList(headerType) {
  return request({
    url: "/api/order/order/invoice/list?headerType="+headerType,
    method: 'get',
  })
}
//删除发票抬头
export function newOrderOrderInvoiceDel(id) {
  return request({
    url: "/api/order/order/invoice/del/"+id,
    method: 'post',
  })
}

//申请退款
export function orderRefundApply(data) {
  return request({
    url: "/api/order/orderRefund/apply",
    method: 'post',
    data
  })
}
//财务-开票/退款设置详情
export function financeInfoConfigInfo(tenantId) {
  return request({
    url: "/api/order/financeInfoConfig/info/"+tenantId,
    method: 'get',
  })
}


//修改后的订单模块

// 订单列表
export function goodsList(data) {
  console.log(data,11)
  return request({
    url: `/api/goods/order/list?current=${data.current}&size=${data.size}&queryType=${data.queryType}&orderStatus=${data.orderStatus}`,
    method: 'get',
    data
  })
}
